module.exports = (function() {
    //Countdown for XX seconds, then transfer visitor to a designated link. Back-fill link to allow quick jump or backup.
    //console.log('In Countdown...');
    if (document.querySelectorAll('.countdown-text')[0]) {
        var guideURL = 'https://www.google.com',
            guideLink = document.querySelectorAll('.countdown-text__guide-link')[0],
            timerText = document.querySelectorAll('.countdown-text__timer')[0],
            timeLeft = 5;

        //Utility to parse through the current location's Query String	
        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        //Update the default Guide URL to the selected Guide.
        //Example: https://pages.clevelandclinic.org/14-0015-BrandResponseHeart_TEST---second-opinion-FORM-Thank-You.html?guideURL=http://www.google.com
        guideURL = getUrlParameter('guideURL');

        //Replace placeholder link URL
        guideLink.setAttribute('href', guideURL);

        //Redirect
        function redirect() {
            document.location.href = guideURL;
        }

        //Setup our count down
        var downloadTimer = setInterval(countdown, 1000);

        function countdown() {
            if (timeLeft == 0) {
                clearTimeout(downloadTimer);
                redirect();
            } else {
                timerText.innerHTML = timeLeft;
                timeLeft--;
            }
        }
    }
})();