/**
 * Settings.
 * @exports CcfComponents.settings
 * @type Object
 * @property {string} [prefix=ccf]
 *   Brand prefix. Should be in sync with `$prefix` Sass variable in ./src/globals/scss/_vars.scss.
 */
const settings = {
    prefix: 'ccf',
};
export default settings;