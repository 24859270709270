module.exports = (function() {
    //Grab associated phone number and attached it to form's hidden differentiator field.
    //console.log('forms js initialized');
    //console.log('our hidden field: ', document.querySelectorAll('[name="pPCampaignDifferentiator"]')[0])

    function manageForm() {
        //console.log('managing form features')
        if (document.querySelectorAll('[name="pPCampaignDifferentiator"]')[0]) {
            var hiddenField = document.querySelectorAll('[name="pPCampaignDifferentiator"]')[0],
                campaignNumber = document.querySelectorAll('.cta--telephone')[0].innerHTML;

            //Utility to parse through the current location's Query String	
            function getUrlParameter(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            }

            if (getUrlParameter('campaignNumber')) {
                campaignNumber = getUrlParameter('campaignNumber');
                document.querySelectorAll('.cta--telephone')[0].innerHTML = campaignNumber;
                document.querySelectorAll('.cta--telephone')[0].setAttribute('href', 'tel:1.' + campaignNumber);
                document.querySelectorAll('.cta--telephone')[0].setAttribute('title', 'Call ' + campaignNumber);
                hiddenField.setAttribute('value', campaignNumber);
                console.log('campaignNumber: ', campaignNumber);
            } else {
                hiddenField.setAttribute('value', campaignNumber);
                console.log('campaignNumber: ', campaignNumber);
            }

        }

    }

    setTimeout(function() { manageForm(); }, 1250);

})();